import { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { validate } from 'email-validator';
import { useTranslation } from 'react-i18next';
import ENVS from '../../config';

import { redText } from '../../config/colors';
import { login } from '../../services/SoundSuitService';
import { useStore } from '../../store';

// import Components
import Title from '../atoms/Title';
import Text from '../atoms/Text';
import InputText from '../atoms/InputText';
import ButtonText from '../atoms/ButtonText';
import GoogleButton from '../molecules/GoogleButton';

interface Props {

};

const SignInScreen: React.FC<Props> = props => {

  async function handleNext() {
    setLoading(true);
    if (validate(email) && typeof password !== "undefined" && password !== "") {
      setError(false);
      setErrorTrialEnded(false);
      try {
        const resultLogin = await login({email, password});
        if (resultLogin.success) {
          try {
            const resUserInfo = await fetch(`${ENVS.API_URL}/userinfo`, {
              method: "GET",
              headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${resultLogin?.data?.token || ""}`
              }
            });
    
            const resData = await resUserInfo.json();
            const data = resData;
            if (!data) {
              throw new Error("No DATA retrived from userinfo");
            }
            const alreadySeenOnBoarding: boolean = data?.firstOnboardingDone || false;
            if (!data.isSubAccount && !alreadySeenOnBoarding) {
              setUserSession(resultLogin.data);
              navigate('/onboarding/step1');
            } else {
              navigate('/redirect', { state : {...resultLogin.data}});
            }
          } catch (error) {
            console.log("ERROR RETRIEVE INFOS : ", error)
          }
        } else {
          if (resultLogin?.message === "trial ended") {
            setErrorTrialEnded(true);
          }
          setError(true);
        }
      } catch (error) {
        console.log("ERROR SIGNIN : ", error);
      }
    } else {
      setError(true);
    }
    setLoading(false);
  }

  const [t] = useTranslation();
  const navigate = useNavigate();
  const setUserSession = useStore.getState().setUserSession;
  const [error, setError] = useState<boolean>(false);
  const [errorTrialEnded, setErrorTrialEnded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  return (
    <Container>
      <Bloc1>
        <Title align="center">{t("Register.titleSignIn")}</Title>
        {/* <GoogleButton /> */}
      </Bloc1>
      {/* <Row>
        <Separator />
          <Text size='small' align="center" color={greyText}>{t('Register.emailSignin')}</Text>
        <Separator />
      </Row> */}
      <Bloc2>
        <InputText
          value={email}
          handleChange={setEmail}
          label={t('Register.email')}
        />
        <InputText
          value={password}
          handleChange={setPassword}
          label={t('Register.passwordLabel')}
          placeholder=''
          password
        />
        <WrapperButton>
          <ButtonText
            onHandleClick={handleNext}
            colorType='red'
            width="100%"
            height={50}
            borderRadius="big"
            fontSize={12}
            loading={loading}
          >
            {t('Register.signinLabelButton')}
          </ButtonText>
          {error && (
            <WrapperEmail>
              <Text color={redText} size="small" >{t('Auth.emailUnknow')}</Text>
            </WrapperEmail>
          )}
          {errorTrialEnded && (
            <WrapperEmail>
              <Text color={redText} size="small" >{t('Auth.trialEnded')}</Text>
            </WrapperEmail>
          )}
        </WrapperButton>
        <Row2>
          <TextClick onClick={() => navigate('/forgot-password')}>
            <Text size='small' color={redText} >{t('Auth.forgotPassword')}</Text>
          </TextClick>
        </Row2>
      </Bloc2>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 50px;
`;
const Bloc1 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100px;
  margin-top: 30px;
  padding-bottom: 40px;
`;
const Bloc2 = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 220px;
  padding-top: 10px;
`;
const WrapperButton = styled.div`
  width: 100%;
  margin-top: 15px;
`;
const WrapperEmail = styled.div`
  width: 100%;
`;
const Row2 = styled.div`
  width: 100%; 
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;
const TextClick = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 5px;
`;

export default SignInScreen;