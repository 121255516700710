import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useStore } from '../../store';
// import Components
import Title from '../atoms/Title';
import Text from '../atoms/Text';
import ButtonText from '../atoms/ButtonText';

interface Props {
};

interface Params {
  email?: string;
};

const RenewPassword: React.FC<Props> = props => {

  function handleRedirectionApp() {
    if (from === 'android') {
      window.location.replace(process.env.REACT_APP_BACK_URL_ANDROID);     
    } else if ( from === 'ios') {
      window.location.replace(process.env.REACT_APP_BACK_URL_IOS); 
    } else if ( from ===  'loxone') {
      window.location.replace(process.env.REACT_APP_BACK_URL_LOXONE); 
    } 
  }

  const { state }: {state: Params} = useLocation();
  const {
    email
  } = state || {};

  const from = useStore(state => state.from);

  const [t] = useTranslation();

  return (
    <Container>
      <Bloc1>
        <Title align="center">{t('Auth.thankYouTitle')}</Title>
      </Bloc1>
      <Bloc2>
        <Text size='xsmall'>{t('Auth.resetPasswordParaph1')}</Text>
        <Gap />
        <Text align='center' >{email}</Text>
        <Gap />
        <Text size='xsmall'>{t('Auth.resetPasswordParaph2')}</Text>
        <WrapperButton>
        {["ios", "android", "loxone"].includes(from) && (
        <ButtonText
          onHandleClick={handleRedirectionApp}
          colorType='red'
          width="100%"
          height={50}
          borderRadius="big"
          fontSize={12}
        >
          {t('Register.buttonRedirect')}
        </ButtonText>
      )}
        </WrapperButton>
      </Bloc2>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 50px;
`;
const Bloc1 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100px;
  margin-top: 30px;
  padding-bottom: 40px;
`;
const Bloc2 = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 220px;
  padding-top: 10px;
`;
const WrapperButton = styled.div`
  width: 100%;
  margin-top: 15px;
`;
const Gap = styled.div`
  width: 100%;
  height: 30px;
`;

export default RenewPassword;