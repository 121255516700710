import { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Routes,
  Route,
  useSearchParams
} from "react-router-dom";

import { white, veryLightLightGrey } from '../config/colors';
import LogoText from '../assets/logoText.png';
import i18n from '../utils/i18n';
import { useStore } from '../store';
// import components
import SelectLang from '../components/molecules/SelectLang';
import StartSignUpScreen from '../components/organisms/StartSignUpScreen';
import SignInScreen from '../components/organisms/SignInScreen';
import CredentialsScreen from '../components/organisms/CredentialsScreen';
import ThankScreen from '../components/organisms/ThankScreen';
import ForgotPassword from '../components/organisms/ForgotPassword';
import RenewPassword from '../components/organisms/RenewPassword';
import RedirectScreen from '../components/organisms/RedirectScreen';
import SonosLogin from '../components/organisms/SonosLogin';
import VerifyScreen from '../components/organisms/VerifyScreen';
import OnBoardingScreen from '../components/organisms/OnBoardingScreen';
import OnBoardingStep1 from '../components/organisms/OnBoardingStep1';
import OnBoardingStep2 from '../components/organisms/OnBoardingStep2';
import OnBoardingStep3 from '../components/organisms/OnBoardingStep3';

interface Params {
  language?: 'fr' | 'en' | 'de' | 'it' | 'es';
  from: 'web' | 'electron' | 'mobile';
  redirectId?: string;
};

const MainLayout = () => {

  const setDataStore = useStore.getState().setData;

  const [params] = useSearchParams();
  const dataURL: Params = Object.fromEntries([...params]) as unknown as Params;

  const [email, setEmail] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [agreed, setAgreed] = useState<boolean>(false);

  useEffect(() => {
    setDataStore(dataURL.language, dataURL.from, dataURL.redirectId);

    if (dataURL.language) {
      i18n.changeLanguage(dataURL.language);
    }
  }, []);

  return (
    <Container>
      <Wrapper>
        <Logo src={LogoText} alt="logo" />
        <MainBloc>
          <WrapperLangSelect>
            <SelectLang lang={dataURL.language || 'en'} />
          </WrapperLangSelect>
          <Routes>
            <Route
              path="/"
              element={<StartSignUpScreen email={email} setEmail={setEmail} />}
            />
            <Route
              path="/onboarding"
              element={<OnBoardingScreen />}
            >
              <Route path="step1" element={<OnBoardingStep1 />} />
              <Route path="step2" element={<OnBoardingStep2 />} />
              <Route path="step3" element={<OnBoardingStep3 />} />
            </Route>
            <Route path="/sonos" element={<SonosLogin />} />
            <Route
              path="/credentials"
              element={
                <CredentialsScreen
                  email={email}
                  setEmail={setEmail}
                  firstName={firstName}
                  setFirstName={setFirstName}
                  lastName={lastName}
                  setLastName={setLastName}
                  password={password}
                  setPassword={setPassword}
                  agreed={agreed}
                  setAgreed={setAgreed}
                />}
            />
            <Route
              path="/thank-you"
              element={
                <ThankScreen
                  email={email}
                />
              }
            />
            <Route
              path="/signin"
              element={
                <SignInScreen />
              }
            />
            <Route
              path="/forgot-password"
              element={
                <ForgotPassword />
              }
            />
            <Route
              path="/new-password"
              element={
                <RenewPassword />
              }
            />
            <Route
              path="/redirect"
              element={
                <RedirectScreen />
              }
            />
            <Route
              path="/app/verify/:token"
              element={
                <VerifyScreen />
              }
            />
          </Routes>
        </MainBloc>
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: hidden;
  background-color: ${veryLightLightGrey};
`;
const Wrapper = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
`;
const MainBloc = styled.div`
  position: relative;
  width: 350px;
  height: 550px;
  background-color: ${white};
  border-radius: 20px;
  z-index: 0;
`;
const Logo = styled.img`
  margin: auto;
  width: 110px;
  height: 27px;
  margin-bottom: 20px;
`;
const WrapperLangSelect = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 100;
`;

export default MainLayout;
