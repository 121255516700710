import { useState } from 'react';
import styled from 'styled-components';
import { Checkbox } from 'antd';
import { useNavigate } from 'react-router-dom';
import { validate } from 'email-validator';
import { TailSpin } from 'react-loading-icons';
import { useTranslation } from 'react-i18next';

import { redText, greyText, linkBlue } from '../../config/colors';
import { register } from '../../services/SoundSuitService';
import {
  URL_PRIVACY,
  URL_TERMS
} from '../../config/hard';
// import Components
import Text from '../atoms/Text';
import InputText from '../atoms/InputText';
import ButtonText from '../atoms/ButtonText';
import GoogleButton from '../molecules/GoogleButton';

interface Props {
  email: string;
  setEmail: (value: string) => void;
  firstName: string;
  setFirstName:(value: string) => void;
  lastName: string;
  setLastName: (value: string) => void;
  password: string;
  setPassword: (value: string) => void;
  agreed: boolean;
  setAgreed: (value: boolean) => void;
};

const CredentialsScreen: React.FC<Props> = props => {


  function openNewTab(url: string) {
    window.open(url, '_blank').focus();
  }

  async function handleSubmit() {
    if(validate(email) && firstName !== "" && lastName !== "" && password !== "" && password.length >= 6 && agreed) {
      isLoading(true);
      setError(false);
      const registerRes = await register({
        company_name: "",
        first_name: firstName,
        last_name: lastName,
        email,
        password,
        trackingId: null,
        accept_tos: agreed,
        accept_receive_info: agreed
      });
      if(registerRes === "") {
        navigate('/thank-you');
      } else {
        setErrorRequest(true);
        console.log('ERROR: ', registerRes);
      }
    } else {
      setError(true);
    }
    isLoading(false);
  }

  const {
    email,
    setEmail,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    password,
    setPassword,
    agreed,
    setAgreed
  } = props;

  const [t] = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState<boolean>(false);
  const [errorRequest, setErrorRequest] = useState<boolean>(false);
  const [loading, isLoading] = useState<boolean>(false);

  return (
    <Container>
      {(error || errorRequest) && (
        <WrapperError>
          <Text size="small" color={redText}>{t('Register.errorForm')}</Text>
        </WrapperError>
      )}
      <InputText
        value={email}
        handleChange={setEmail}
        label={t('Register.email')}
        placeholder=''
      />
      <Row>
        <InputText
          value={firstName}
          handleChange={setFirstName}
          label={t('Register.firstnameLabel')}
          placeholder=''
        />
        <InputText
          value={lastName}
          handleChange={setLastName}
          label={t('Register.lastnameLabel')}
          placeholder=''
        />
      </Row>
      <InputText
        value={password}
        handleChange={setPassword}
        label={t('Register.passwordLabel')}
        placeholder=''
        password
      />
      {!loading ? (
        <ButtonText
          onHandleClick={handleSubmit}
          colorType='red'
          width="100%"
          height={50}
          borderRadius="big"
          fontSize={12}
        >
          {t('Register.createAccountLabel')}
        </ButtonText>
      ):(
        <WrapperLoading>
          <TailSpin stroke={redText} height="2em" />
        </WrapperLoading>
      )}
      <Checkbox style={{marginTop: 15}} checked={agreed} onChange={() => setAgreed(!agreed)}>
        <Text size='small' color={greyText}>{t('Register.agreeLabel_part_1')}</Text>
        <Text>{' '}</Text>
        <Text onClick={() => openNewTab(URL_TERMS)} size='small' color={linkBlue}>{t('Register.agreeLabel_part_2')}</Text>
        <Text>{' '}</Text>
        <Text size='small' color={greyText}>{t('Register.agreeLabel_part_3')}</Text>
        <Text>{' '}</Text>
        <Text onClick={() => openNewTab(URL_PRIVACY)} size='small' color={linkBlue}>{t('Register.agreeLabel_part_4')}</Text>
      </Checkbox>
      <Gap/>
      {/* <Row center>
        <Text size='verysmall' color={greyText} >{t('Register.orLabel')}</Text>
      </Row>
      <Gap />
      <GoogleButton />
      <Gap /> */}
      <Gap />
      <Gap />
      <Row center>
        <Text size='small' color={greyText} >{t('Register.AlreadyLabel')}</Text>
        <TextClick onClick={() => {}}>
          <Text size='small' color={redText} >{` ${t('Register.logHereLabel')} `}</Text>
          <Text size='small' color={redText} >&#8594;</Text>
        </TextClick>
      </Row>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 50px;
`;
const Row = styled.div<{center?: boolean}>`
  width: 110%; 
  display: flex;
  align-items: center;
  justify-content: ${({center}) => center ? 'center':'space-between'};
`;
const TextClick = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 5px;
`;
const Gap = styled.div`
  width: 100%;
  height: 10px;
`;
const WrapperError = styled.div`
  position: absolute;
  top: 10px;
  right: 20px;
`;
const WrapperLoading = styled.div`
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export default CredentialsScreen;
