import styled from 'styled-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ENVS from '../../config';
import { useNavigate } from 'react-router-dom';

import { redText } from '../../config/colors';
import type { Genres, ToggleLabels } from '../../services/SoundSuitServiceTypes';
import { useStore } from '../../store';
// import components
import GenreOptions from '../molecules/GenreOptions';
import Text from '../atoms/Text';
import Title from '../atoms/Title';
import ButtonText from '../atoms/ButtonText';

export interface OnBoardingSettings {
  business_type: string;
  customer_age: string[];
  styles: string[];
  genres: Genres[];
  bpmRange?: string;
  languages: string[];
}

const OnBoardingStep3 = () => {

  async function handleSubmit() {

    try {
      if (userSession) {
        const {
          business,
          age,
          styles,
        } = onBoardingInfos;
  
        let genresData: Genres[] = selectedGenres.slice();
        if (smartGenres) {
          genresData = ['pop', 'dancehall', 'lounge'];
        }
        const dataToSend: OnBoardingSettings = {
          business_type: business,
          customer_age: [`<${age[1]}`],
          styles: styles,
          genres: genresData,
          languages: ["english"]
        };
        await fetch(`${ENVS.API_URL}/user/onboarding`, {
          method: "POST",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${userSession.token}`
          },
          body: JSON.stringify(dataToSend)
        });
        await fetch(`${ENVS.API_URL}/user/settings`, {
          method: "POST",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${userSession.token}`
          },
          body: JSON.stringify({firstOnboardingDone: true})
        });
        navigate('/redirect', { state : userSession});
      }

    } catch (error) {
      console.log("ERROR: ", error);
    }

  }

  const [t] = useTranslation();
  const navigate = useNavigate();
  const onBoardingInfos = useStore.getState().onBoardingInfos;
  const userSession = useStore.getState().userSession;

  const [ selectedGenres, setSelectedGenres ] = useState<(Genres)[]>([]);
  const [ smartGenres, setSmartGenres ] = useState<boolean>(false);

  return (
    <Container>
      <Header>
        <Text size="small" color={redText}>{`${t('onBoarding.step')} 3`}</Text>
        <Title align='center'>{t('onBoarding.step3Title')}</Title>
      </Header>
      <div>
        <GenreOptions
          selected={selectedGenres}
          setSelected={setSelectedGenres}
          smartGenres={smartGenres}
          setSmartGenres={setSmartGenres}
        />
      </div>
      <Footer>
        <ButtonText
            onHandleClick={handleSubmit}
            colorType='red'
            width="80%"
            height={50}
            borderRadius="big"
            fontSize={12}
          >
            {t('onBoarding.next')}
        </ButtonText>
      </Footer>
    </Container>
  );
}

const Container = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
`;
const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;


export default OnBoardingStep3;