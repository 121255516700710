import i18n from '../utils/i18n';
import {
  Mood,
  MoodLabel,
  Genres,
  Days,
} from '../services/SoundSuitServiceTypes';

export const URL_REFERER_BUSINESS = 'https://soundsuit.fm?fpr=';
export const APPLE_STORE_URL =
  'https://apps.apple.com/app/soundsuit/id1526687751';
export const GOOGLE_STORE_URL =
  'https://play.google.com/store/apps/details?id=fm.soundsuit.app';

export interface IdToLabel {
  wellness_spa: string;
  restaurant: string;
  hotel: string;
  cafe: string;
  design_store: string;
  hair_salon: string;
  fashion_boutique: string;
  bar: string;
  event: string;
  work_place: string;
  medical_practice: string;
  fintess_studio: string; // gym
  pop: string;
  hit: string;
  rock: string;
  disco: string;
  christmas: string;
  jazz: string;
  soul: string;
  acoustic: string;
  'hip-hop': string;
  dance: string;
  dancehall: string;
  lounge: string;
  'r&b': string;
  folk: string;
  chillout: string;
  smartgenres: string;
  party: string;
  exciting: string;
  happy: string;
  loungy: string;
  chill: string;
  calm: string;
  smartmood: string;
  mainstream: string;
  trendy: string;
  alternative: string;
  woman: string;
  man: string;
  manWoman: string;
  nature: string;
  fitness: string;
  world: string;
  reggae: string;
  'jazz-pop': string;
  electronic: string;
  liked_songs: string;
  'bossa-nova': string;
  'workout-pop': string;
  'workout-rock': string;
  'workout-hip-hop': string;
  // GOING TO BE DEPRECIETED
  'nature sound': string;
  country: string;
  'dance&electronic': string;
  'dance & electronic': string;
  'world-music': string;
  'jazz&soul': string;
  'jazz-soul': string;
}

export const idToLabel = (): IdToLabel => ({
  design_store: i18n.t('Settings.design_store'),
  bar: i18n.t('Settings.bar'),
  event: i18n.t('Settings.event'),
  hotel: i18n.t('Settings.hotel'),
  work_place: i18n.t('Settings.workplace'),
  medical_practice: i18n.t('Settings.medical_practice'),
  restaurant: i18n.t('Settings.restaurant'),
  wellness_spa: i18n.t('Settings.wellness_spa'),
  cafe: i18n.t('Settings.cafe'),
  christmas: i18n.t('Settings.christmas'),
  fashion_boutique: i18n.t('Settings.fashion_boutique'),
  fintess_studio: i18n.t('Settings.gym'),
  hair_salon: i18n.t('Settings.hair_salon'),
  pop: i18n.t('Settings.pop'),
  hit: i18n.t('Settings.hit'),
  rock: i18n.t('Settings.rock'),
  disco: i18n.t('Settings.disco'),
  jazz: i18n.t('Settings.jazz'),
  soul: i18n.t('Settings.soul'),
  acoustic: i18n.t('Settings.acoustic'),
  'hip-hop': i18n.t('Settings.hiphop'),
  dance: i18n.t('Settings.dance'),
  dancehall: i18n.t('Settings.danceHall'),
  lounge: i18n.t('Settings.lounge'),
  'r&b': i18n.t('Settings.rnb'),
  folk: i18n.t('Settings.folk'),
  chillout: i18n.t('Settings.chillout'),
  smartgenres: i18n.t('Settings.smartGenreLabel'),
  party: i18n.t('Settings.party'),
  exciting: i18n.t('Settings.exciting'),
  happy: i18n.t('Settings.happy'),
  loungy: i18n.t('Settings.loungy'),
  chill: i18n.t('Settings.chill'),
  calm: i18n.t('Settings.calm'),
  smartmood: i18n.t('Settings.smartMood'),
  mainstream: i18n.t('Settings.mainstream'),
  trendy: i18n.t('Settings.trendy'),
  alternative: i18n.t('Settings.alternative'),
  woman: i18n.t('Settings.woman'),
  man: i18n.t('Settings.man'),
  manWoman: i18n.t('Settings.manWoman'),
  nature: i18n.t('Settings.nature'),
  fitness: i18n.t('Settings.fitness'),
  world: i18n.t('Settings.world'),
  reggae: i18n.t('Settings.reggae'),
  'jazz-pop': i18n.t('Settings.jazzPop'),
  electronic: i18n.t('Settings.electronic'),
  liked_songs: i18n.t('Settings.likedSongs'),
  'bossa-nova': i18n.t('Settings.bossaNova'),
  'workout-pop': i18n.t('Settings.workoutPop'),
  'workout-rock': i18n.t('Settings.workoutRock'),
  'workout-hip-hop': i18n.t('Settings.workoutHipHop'),
  // GOING TO BE DEPRECIETED
  'nature sound': i18n.t('Settings.nature'),
  country: i18n.t('Settings.folk'),
  'dance&electronic': i18n.t('Settings.dance'),
  'dance & electronic': i18n.t('Settings.dance'),
  'world-music': i18n.t('Settings.world'),
  'jazz&soul': i18n.t('Settings.soul'),
  'jazz-soul': i18n.t('Settings.soul'),
});

export const moodSettings: Mood = {
  party: i18n.t('Settings.rangeParty'),
  exciting: i18n.t('Settings.rangeExciting'),
  happy: i18n.t('Settings.rangeHappy'),
  loungy: i18n.t('Settings.rangeLoungy'),
  chill: i18n.t('Settings.rangeChill'),
  calm: i18n.t('Settings.rangeCalm'),
  smartmood: i18n.t('Settings.rangeSmartMood'),
};

interface ReverseMoodSettings {
  [key: string]: MoodLabel;
}
export const reverseMoodSettings: ReverseMoodSettings = {
  [i18n.t('Settings.rangeParty')]: 'party',
  [i18n.t('Settings.rangeExciting')]: 'exciting',
  [i18n.t('Settings.rangeHappy')]: 'happy',
  [i18n.t('Settings.rangeLoungy')]: 'loungy',
  [i18n.t('Settings.rangeChill')]: 'chill',
  [i18n.t('Settings.rangeCalm')]: 'calm',
  [i18n.t('Settings.rangeSmartMood')]: 'smartmood',
};

interface ReverseDayTranslate {
  [key: string]: Days;
}

export interface ListMood {
  label: MoodLabel;
  img: string;
}

export const listMoods: ListMood[] = [
  {label: 'party', img: '../assets/images/party.png'},
  {label: 'exciting', img: '../assets/images/exciting.png'},
  {label: 'happy', img: '../assets/images/happy.png'},
  {label: 'loungy', img: '../assets/images/loungy.png'},
  {label: 'chill', img: '../assets/images/chill.png'},
  {label: 'calm', img: '../assets/images/calm.png'},
  {label: 'smartmood', img: '../assets/images/smartmood.png'},
];

export interface ListGenres {
  label: Genres;
  img: string;
}

export const listGenres: ListGenres[] = [
  {label: 'pop', img: '../assets/images/pop.jpg'},
  {label: 'rock', img: '../assets/images/rock.jpg'},
  {label: 'hit', img: '../assets/images/hits.jpg'},
  {label: 'jazz', img: '../assets/images/jazz.jpg'},
  {label: 'soul', img: '../assets/images/soul.jpg'},
  {label: 'acoustic', img: '../assets/images/acoustic.jpg'},
  {label: 'hip-hop', img: '../assets/images/hipHop.jpg'},
  {label: 'disco', img: '../assets/images/disco.jpg'},
  {label: 'dance', img: '../assets/images/dance.jpg'},
  {label: 'lounge', img: '../assets/images/lounge.jpg'},
  {label: 'r&b', img: '../assets/images/rB.jpg'},
  {label: 'dancehall', img: '../assets/images/dancehall.jpg'},
  {label: 'chillout', img: '../assets/images/chillout.jpg'},
  {label: 'folk', img: '../assets/images/country.jpg'},
  {label: 'christmas', img: '../assets/images/christmas.jpg'},
  {label: 'nature', img: '../assets/images/nature.jpg'},
  {label: 'fitness', img: '../assets/images/fitness.jpg'},
  {label: 'world', img: '../assets/images/world.jpg'},
  {label: 'reggae', img: '../assets/images/reggae.jpg'},
  {label: 'jazz-pop', img: '../assets/images/jazz-pop.jpg'},
  {label: 'electronic', img: '../assets/images/electronic.jpg'},
  {label: 'liked_songs', img: '../assets/images/loved.jpg'},
];
