import { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { validate } from 'email-validator';
import { useTranslation } from 'react-i18next';

import { veryLightLightGrey, redText, greyText } from '../../config/colors';
// import Components
import Title from '../atoms/Title';
import Text from '../atoms/Text';
import InputText from '../atoms/InputText';
import ButtonText from '../atoms/ButtonText';
import GoogleButton from '../molecules/GoogleButton';

interface Props {
  email: string;
  setEmail: (value: string) => void;
};

const StartSignUpScreen: React.FC<Props> = props => {

  function handleNext() {
    if (validate(email)) {
      setError(false);
      navigate('/credentials');
    } else {
      setError(true);
    }
  }

  const {
    email,
    setEmail
  } = props;

  const [t] = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState<boolean>(false);

  return (
    <Container>
      <Bloc1>
        <Title>{t("Register.title")}</Title>
        {/* <GoogleButton /> */}
      </Bloc1>
      {/* <Row>
        <Separator />
          <Text size='small' align="center" color={greyText}>{t('Register.emailRegister')}</Text>
        <Separator />
      </Row> */}
      <Bloc2>
        <InputText
          value={email}
          handleChange={setEmail}
          label={t('Register.email')}
          placeholder={t('Register.emailPlaceholder')}
        />
        {error && (
          <WrapperEmail>
            <Text color={redText} size="small" >{t('Register.errorEmail')}</Text>
          </WrapperEmail>
        )}
        <WrapperButton>
          <ButtonText
            onHandleClick={handleNext}
            colorType='red'
            width="100%"
            height={50}
            borderRadius="big"
            fontSize={12}
          >
            {t('Register.signupLabelButton')}
          </ButtonText>
        </WrapperButton>
        <Row2>
          <Text size='small' color={greyText} >{t('Register.AlreadyLabel')}</Text>
          <TextClick onClick={() => navigate('/signin')}>
            <Text size='small' color={redText} >{` ${t('Register.logHereLabel')}`}</Text>
            <Text size='small' color={redText} >&#8594;</Text>
          </TextClick>
        </Row2>
      </Bloc2>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 50px;
`;
const Bloc1 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100px;
  margin-top: 30px;
  padding-bottom: 40px;
`;
const Bloc2 = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 220px;
  padding-top: 10px;
`;
const Row = styled.div`
  width: 100%; 
  display: flex;
  align-items: center;
  justify-content: space-around;
`;
const Row2 = styled.div`
  width: 100%; 
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 45px;
`;
const Separator = styled.div`
  height: 1px;
  width: 30px;
  background-color: ${veryLightLightGrey};
`;
const TextClick = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 5px;
`;
const WrapperButton = styled.div`
  width: 100%;
  margin-top: 15px;
`;
const WrapperEmail = styled.div`
  position: absolute;
  top: 70px;
  width: 100%;
`;

export default StartSignUpScreen;