import styled from 'styled-components';
import { CheckOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { weakRed, redText, greyText, linkBlue } from '../../config/colors';
import {URL_DOWNLOAD_APP} from '../../config/hard';
import { useStore } from '../../store';
// import Components
import Title from '../atoms/Title';
import Text from '../atoms/Text';
import ButtonText from '../atoms/ButtonText';

interface Props {
  email: string;
};

const ThankScreen: React.FC<Props> = props => {

  function handleRedirection() {
    window.location.replace(URL_DOWNLOAD_APP);
  }

  function handleRedirectionApp() {
    if (from === 'android') {
      window.location.replace(process.env.REACT_APP_BACK_URL_ANDROID);     
    } else if ( from === 'ios') {
      window.location.replace(process.env.REACT_APP_BACK_URL_IOS); 
    } else if ( from ===  'loxone') {
      window.location.replace(process.env.REACT_APP_BACK_URL_LOXONE); 
    } 
  }

  const from = useStore(state => state.from);

  const { email } = props;
  const [t] = useTranslation();

  return (
    <Container>
      <Square>
        <CheckOutlined style={{ fontSize: '38px', margin: 'auto', color: redText }}  />
      </Square>
      <Title>{t('Register.thankLabel')}</Title>
      <BigGap />
      <Text size='small' color={greyText} >{t('Register.sendEmailLabel')}</Text>
      <Text size='small' color={linkBlue}>{email}</Text>
      <Gap />
      <Gap />
      <Row center>
        <Text size='small' align="center" color={greyText} >{t('Register.explanationLabel')}</Text>
      </Row>
      <BigGap />
      {["ios", "android", "loxone"].includes(from) && (
        <ButtonText
          onHandleClick={handleRedirectionApp}
          colorType='red'
          width="100%"
          height={50}
          borderRadius="big"
          fontSize={12}
        >
          {t('Register.buttonRedirect')}
        </ButtonText>
      )}
      {from === 'electron' && (
        <ButtonText
          onHandleClick={handleRedirection}
          colorType='red'
          width="100%"
          height={50}
          borderRadius="big"
          fontSize={12}
        >
          {t('Register.downloadLabel')}
        </ButtonText>
      )}
    </Container>
  );
}


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  padding-left: 50px;
  padding-right: 50px;
`;
const Square = styled.div`
  width: 100px;
  height: 100px;
  background-color: ${weakRed};
  border-radius: 25px;
  display: flex;
  margin-bottom: 15px;
`;
const Row = styled.div<{center?: boolean}>`
  width: 70%; 
  display: flex;
  align-items: center;
  justify-content: ${({center}) => center ? 'center':'flex-start'};
`;
const Gap = styled.div`
  width: 100%;
  height: 10px;
`;
const BigGap = styled.div`
  width: 100%;
  height: 40px;
`;

export default ThankScreen;