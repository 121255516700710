import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CheckOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { weakRed, redText, greyText } from '../../config/colors';
import { useStore } from '../../store';
// import Components
import Title from '../atoms/Title';
import Text from '../atoms/Text';
import ButtonText from '../atoms/ButtonText';

interface Params {
  token?: string;
  refreshToken?: string;
  expiresAt?: string;
  refreshTokenExpiresAt?: string;
};
interface Props {

};

const RedirectScreen: React.FC<Props> = props => {

  function handleRedirection() {
    if (backURL && token && refreshToken) {
      const url = `${backURL}?token=${token}&refreshToken=${refreshToken}&expiresAt=${expiresAt}&refreshTokenExpiresAt=${refreshTokenExpiresAt}`;
      window.location.replace(url as string); 
    }    
  }

  const { state }: { state: Params } = useLocation();

  const {
    token,
    refreshToken,
    refreshTokenExpiresAt,
    expiresAt
  } = state || {};

  const [backURL, setBackURL] = useState<string>("");

  const from = useStore(state => state.from);
  const redirectId = useStore(state => state.redirectId);

  const [t] = useTranslation();

  useEffect(() => {
    if (from) {
      switch (from) {
        case "ios": {
          const params = redirectId ? `?redirectId=${redirectId}&` : "?";
          setBackURL(process.env.REACT_APP_BACK_URL_IOS + params);
        }
          break;
        case "android": {
          const params = redirectId ? `?redirectId=${redirectId}&` : "?";
          setBackURL(process.env.REACT_APP_BACK_URL_ANDROID + params);
        }
          break;
        case "loxone": {
          const params = redirectId ? `/${redirectId}?` : "?";
          setBackURL(process.env.REACT_APP_BACK_URL_LOXONE + params);
        }
          break;
        case "electron": 
          const dataAuth = {token, refreshToken, refreshTokenExpiresAt, expiresAt, redirectId};
          window.parent.postMessage(JSON.stringify(dataAuth), "*");
          break;
      }
    }
  }, [from, redirectId, token, refreshToken, expiresAt, refreshTokenExpiresAt]);

  useEffect(() => {
    if (backURL && token && refreshToken) {
      const url = `${backURL}token=${token}&refreshToken=${refreshToken}&expiresAt=${expiresAt}&refreshTokenExpiresAt=${refreshTokenExpiresAt}`;
      setTimeout(() => {
        window.location.replace(url as string);      
      }, 2000);
    }
  }, [backURL]);

  return (
    <Container>
      <Square>
        <CheckOutlined style={{ fontSize: '38px', margin: 'auto', color: redText }}  />
      </Square>
      <Title>{t('Register.connectedTitle')}</Title>
      <BigGap />
      <Gap />
      <Row center>
        <Text size='small' align="center" color={greyText} >{t('Register.textConnected')}</Text>
      </Row>
      <BigGap />
      <ButtonText
        onHandleClick={handleRedirection}
        colorType='red'
        width="100%"
        height={50}
        borderRadius="big"
        fontSize={12}
      >
        {t('Register.buttonRedirect')}
      </ButtonText>
    </Container>
  );
}


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  padding-left: 50px;
  padding-right: 50px;
`;
const Square = styled.div`
  width: 100px;
  height: 100px;
  background-color: ${weakRed};
  border-radius: 25px;
  display: flex;
  margin-bottom: 15px;
`;
const Row = styled.div<{center?: boolean}>`
  width: 70%; 
  display: flex;
  align-items: center;
  justify-content: ${({center}) => center ? 'center':'flex-start'};
`;
const Gap = styled.div`
  width: 100%;
  height: 10px;
`;
const BigGap = styled.div`
  width: 100%;
  height: 40px;
`;

export default RedirectScreen;